import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import Loadable from 'ui-component/Loadable';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const ContactPage = Loadable(lazy(() => import('views/pages/contact-us')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/saas-pages/PrivacyPolicy')));
const TermsConditions = Loadable(lazy(() => import('views/pages/saas-pages/TermsConditions')));

export default function ThemeRoutes() {
    return useRoutes([
        { 
            path: '/', 
            element: <PagesLanding /> 
        },
        {
            path: '/contact',
            element: <ContactPage />
        },
        {
            path: '/privacy',
            element: <PrivacyPolicy />
        },
        {
            path: '/terms',
            element: <TermsConditions />
        }
    ]);
}
